import Head from "next/head";

import { Button } from "@components/button";

import { makeLpTitle } from "@utils/makeLpTitle";
import { dangerHTML } from "@utils/danger";
import { LP_URL } from "@constants/lpUrl";

export default function Custom404() {
  const metaTitle = "404 Page Not Found";

  return (
    <div>
      <Head>
        <title>{makeLpTitle(metaTitle)}</title>

        <link rel="canonical" href={LP_URL} />
        <meta property="og:url" content={LP_URL} />
        <script
          type="application/ld+json"
          {...dangerHTML(
            JSON.stringify({
              "@context": "http://www.schema.org",
              "@type": "Organization",
              name: "Lonely Planet",
              url: LP_URL,
              sameAs: [
                "https://www.instagram.com/lonelyplanet/",
                "https://www.facebook.com/lonelyplanet",
                "https://www.twitter.com/lonelyplanet",
                "https://www.tiktok.com/@lonelyplanet",
                "https://www.youtube.com/lonelyplanet",
                "https://www.pinterest.com/lonelyplanet/",
                "https://www.linkedin.com/company/lonely-planet",
              ],
              logo: "https://lonelyplanetstatic.imgix.net/marketing/2022/lp_logo_google.png",
            })
          )}
        />
        <script
          type="application/ld+json"
          {...dangerHTML(
            JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebSite",
              url: LP_URL,
              potentialAction: {
                "@type": "SearchAction",
                target: `${LP_URL}/search?q={search_term_string}`,
                "query-input": "required name=search_term_string",
              },
            })
          )}
        />
      </Head>

      <div className="flex-col mt-10 mb-40 text-center">
        <h1 className="text-4xl">We&#39;re Sorry.</h1>
        <h3 className="mb-8 text-xl">
          The page you&#39;re looking for doesn&#39;t exist, but we&#39;ve got a
          lot of ideas for your next trip.
        </h3>
        <Button href="/" className="mt-6 btn btn-primary">
          Back to the Homepage
        </Button>
      </div>
    </div>
  );
}

export async function getStaticProps() {
  return {
    props: {},
    revalidate: 10,
  };
}

Custom404.defaultProps = {
  pageProps: {},
};
